import { UploadFile } from 'antd'
import { atom } from 'jotai'

export const stepAtom = atom<1 | 2 | 3>(1)
export const targetListAtom = atom<string[]>([])
export const portraitAtom = atom<string>('')
export const resultAtom = atom<
    Array<{
        img_name: string
        img_url: string
        number: number
    }>
>([])
