import React, { useState } from 'react'
import {
  GetProp,
  UploadFile,
  UploadProps,
  Modal,
  message,
  Upload,
  Spin,
} from 'antd'
import { uploadImage } from '../../../utils'

export default function UploadSection({
  onFileChange,
  fileList,
  ...props
}: {
  onFileChange: (fileList: UploadFile[]) => void
  fileList: UploadFile[]
} & UploadProps) {
  const hasValidUpload = fileList.some((file) => file.status === 'done')
  const uploading = fileList.some((file) => file.status === 'uploading')

  return (
    <Upload
      fileList={fileList}
      multiple={false}
      accept="image/*"
      showUploadList={false}
      onChange={({ fileList: newFileList }) => {
        onFileChange(newFileList)
      }}
      beforeUpload={(file) => {
        const isImage = file.type.startsWith('image/')
        if (!isImage) {
          message.error('上传的不是有效图片！')
        }
        return isImage || Upload.LIST_IGNORE
      }}
      customRequest={(options) => {
        uploadImage(
          {
            file: options.file as File,
            token: '',
            post_ID: 0,
          },
          {
            onUploadProgress: (pe) => {
              options.onProgress?.(pe)
            },
          },
        )
          .then(({ data }) => {
            if (!data.uploaded) {
              options.onError?.(new Error('上传失败'))
              return
            }
            options.onSuccess?.(data.url)
          })
          .catch((e) => {
            message.error('上传失败')
            options.onError?.(e)
          })
      }}
      {...props}
    >
      <div className="ai-upload">
        {!hasValidUpload && (
          <div
            className="ai-upload-tip text-secondary text-center"
            style={{ width: '100%' }}
          >
            <div className="m-auto">
              {uploading && <Spin />}
              {!uploading && (
                <>
                  <i className="text-40 iconfont icon-jia_plus" />
                  <div className="text-md">上传/选择模特</div>
                  <div className="text-muted text-xs">
                    <p>图片大小：小于 20M</p>
                    <p>图片分辨率：小于 3000x3000</p>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {hasValidUpload && (
          <div className="ai-upload-images py-3 py-md-4">
            <div className="row g-3 justify-content-center">
              <div className="col-10">
                <div className="item rounded active">
                  <div className="media media-3x4">
                    <div className="media-content">
                      <img
                        src={fileList[0].response}
                        decoding="async"
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="item-action">
                    <span onClick={() => void onFileChange([])}>
                      <i className="iconfont icon-guanbi-xiao_close-small" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Upload>
  )
}
