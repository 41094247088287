import { atom, useAtom } from 'jotai'
import clsx from 'clsx'
import {
  enhancementAtom,
  imgUrlsAtom,
  modelIdAtom,
  resultAtom,
  stepAtom,
} from './atoms'
import Step1Section from './components/step1-section'
import Step2Section from './components/step2-section'
import Step3Section from './components/step3-section'
import { Button } from 'antd'
import apiInstance from '../../api/axios'
import { useRequest } from 'ahooks'
import { useState } from 'react'

function Aside() {
  const [
    step,
    setStep,
  ] = useAtom(stepAtom)

  const [targetList] = useAtom(imgUrlsAtom)
  const [modelId] = useAtom(modelIdAtom)
  const [enhancement] = useAtom(enhancementAtom)
  const [
    _,
    setResults,
  ] = useAtom(resultAtom)

  const [
    polling,
    setPolling,
  ] = useState(false)
  const { run: queryTask, cancel: cancelPolling } = useRequest(
    (taskId: string) =>
      apiInstance.get('/nicetheme/v1/fast_imgs', {
        params: {
          task_id: taskId,
        },
      }),
    {
      pollingInterval: 5000,
      manual: true,
      onSuccess({ data }) {
        if (data.data.code > 0) {
          setPolling(false)
          cancelPolling()
        }

        if (data.data.code === 1) {
          setResults(data.data.data)
        }
      },
    },
  )

  const { run, loading } = useRequest(
    () =>
      apiInstance.post('/nicetheme/v1/fast_img', {
        model_id: modelId,
        img_url: targetList,
        enhancement,
        face_img: enhancement ? targetList[0] : '',
      }),
    {
      manual: true,
      onSuccess({ data }) {
        if (!data.success) return
        queryTask(data.data.task_id)
      },
    },
  )

  return (
    <div className="ai-aside block p-3 p-md-4">
      <div className="ai-steps">
        <div className={clsx('item', step === 1 && 'active')}>
          <div className="item-dot">
            <i className="iconfont icon-xiaoyan_check" />
          </div>
          <div className="item-content">
            <div className="item-title">选择/上传模特</div>
            <div className="text-secondary text-xs ">
              <p>可自行上传，或者从已有权限素材中选择心仪图片。</p>
              <p>建议选择脸型与目标人脸相似的模特图片</p>
            </div>
          </div>
        </div>
        <div className={clsx('item', step === 2 && 'active')}>
          <div className="item-dot">
            <i className="iconfont icon-xiaoyan_check" />
          </div>
          <div className="item-content">
            <div className="item-title">选择/自己的模型</div>
            <div className="text-secondary text-xs ">
              如尚未建模，请先通过 AI 建模功能进行模型生成
            </div>
          </div>
        </div>
        <div className={clsx('item', step === 3 && 'active')}>
          <div className="item-dot">
            <i className="iconfont icon-xiaoyan_check" />
          </div>
          <div className="item-content">
            <div className="item-title">开始写真</div>
          </div>
        </div>
      </div>
      <div className="ai-actions mt-auto">
        <div className="text-muted text-xs text-center mb-1">
          已选中 {targetList.length} 张，共{' '}
          {targetList.length * wpApiSettings.ai_model_portrait_credit} 积分
        </div>
        {step < 2 ? (
          <button
            className="ai-create-pay btn btn-primary btn-block"
            disabled={targetList.length === 0}
            onClick={() => setStep((step + 1) as any)}
          >
            下一步
            <div className="ai-price-tips">
              {wpApiSettings.ai_model_portrait_credit} 积分 / 张
            </div>
          </button>
        ) : (
          <Button
            block
            size="large"
            type="primary"
            onClick={() => run()}
            loading={loading || polling}
            disabled={targetList.length === 0 || !modelId}
          >
            生成写真
            <div className="ai-price-tips">
              {wpApiSettings.ai_model_portrait_credit} 积分 / 张
            </div>
          </Button>
        )}
      </div>
    </div>
  )
}

export default function AIModelPage() {
  return (
    <>
      <main className="site-main ai-main">
        <Aside />
        <div className="ai-content block p-3 p-md-4">
          <Step1Section />
          <Step2Section />
          <Step3Section />
        </div>
      </main>
    </>
  )
}
