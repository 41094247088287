import { UploadFile } from 'antd'
import { atom, useAtom } from 'jotai'

export const stepAtom = atom<1 | 2 | 3>(1)
export const imgUrlsAtom = atom<string[]>([])
export const modelIdAtom = atom<string>('')
export const enhancementAtom = atom(false)
export const faceImgUrlAtom = atom<string>('')
export const resultAtom = atom<
    Array<{
        img_name: string
        img_url: string
        model_id: string
        cost_time: any
    }>
>([])
