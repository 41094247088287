import React, { useState } from 'react'
import { useRequest } from 'ahooks'
import { atom, useAtom } from 'jotai'
import apiInstance from '../api/axios'

import { PlusOutlined } from '@ant-design/icons'
import {
  Modal,
  Upload,
  message,
  GetProp,
  UploadFile,
  UploadProps,
  Button,
} from 'antd'
import { uploadImage } from '../utils/api'

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

function UploadSection({
  onChange,
  fileList,
}: {
  onChange: (fileList: UploadFile[]) => void
  fileList: UploadFile[]
}) {
  const [
    previewOpen,
    setPreviewOpen,
  ] = useState(false)
  const [
    previewImage,
    setPreviewImage,
  ] = useState('')
  const [
    previewTitle,
    setPreviewTitle,
  ] = useState('')

  const handleCancel = () => setPreviewOpen(false)

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
    )
  }

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    onChange(newFileList)
  }

  return (
    <>
      <Upload
        listType="picture-card"
        fileList={fileList}
        multiple
        maxCount={20}
        accept="image/*"
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={(file) => {
          const isImage = file.type.startsWith('image/')
          if (!isImage) {
            message.error('上传的不是有效图片！')
          }
          return isImage || Upload.LIST_IGNORE
        }}
        customRequest={(options) => {
          uploadImage(
            {
              file: options.file as File,
              token: '',
              post_ID: 0,
            },
            {
              onUploadProgress: (pe) => {
                options.onProgress?.(pe)
              },
            },
          )
            .then(({ data }) => {
              if (!data.uploaded) {
                options.onError?.(new Error('上传失败'))
                return
              }
              options.onSuccess?.(data.url)
            })
            .catch((e) => {
              message.error('上传失败')
              options.onError?.(e)
            })
        }}
      >
        <button style={{ border: 0, background: 'none' }} type="button">
          <PlusOutlined />
        </button>
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  )
}

const trainTasksAtom = atom<
  { taskId: number; thumbnail: string; state: number }[]
>([])

function Aside() {
  //   const { signature } = useSignature()
  //   const [
  //     trainTasks,
  //     setTrainTasks,
  //   ] = useAtom(trainTasksAtom)
  //   const { cancel } = useRequest(
  //     () =>
  //       Promise.all(
  //         trainTasks.map((task) =>
  //           queryAITrainingTask({ job_id: `${task.taskId}` }, signature!),
  //         ),
  //       ),
  //     {
  //       pollingInterval: 5000,
  //       ready: !!signature && trainTasks.length > 0,
  //       refreshDeps: [trainTasks.length],
  //       onSuccess(e) {
  //         if (e.every((r) => r.data.state > 1)) {
  //           // 任务均执行成功或失败

  //           cancel()
  //         }
  //         setTrainTasks(
  //           trainTasks.map((t) => ({
  //             ...t,
  //             state: e.find((r) => r.data.id === t.taskId)?.data.state || 0,
  //           })),
  //         )
  //       },
  //     },
  //   )

  const { data } = useRequest(
    () => apiInstance.get('/nicetheme/v1/modeling_tasks'),
    {
      pollingInterval: 5000,
    },
  )

  const trainTasks = data?.data?.data || []

  return (
    <div className="ai-aside block p-3 p-md-4">
      <div className="mb-4">
        <h2 className="text-lg mb-3">样图图片区</h2>
        <div className="row g-2">
          <div className="col-4">
            <div className="item rounded-1">
              <div className="media">
                <div className="media-content">
                  <img
                    src="https://images.unsplash.com/photo-1674851993235-f730432c37f8?q=80&w=3687&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    decoding="async"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="item rounded-1">
              <div className="media">
                <div className="media-content">
                  <img
                    src="https://images.unsplash.com/photo-1674851993235-f730432c37f8?q=80&w=3687&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    decoding="async"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="item rounded-1">
              <div className="media">
                <div className="media-content">
                  <img
                    src="https://images.unsplash.com/photo-1674851993235-f730432c37f8?q=80&w=3687&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    decoding="async"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="item rounded-1">
              <div className="media">
                <div className="media-content">
                  <img
                    src="https://images.unsplash.com/photo-1674851993235-f730432c37f8?q=80&w=3687&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    decoding="async"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="item rounded-1">
              <div className="media">
                <div className="media-content">
                  <img
                    src="https://images.unsplash.com/photo-1674851993235-f730432c37f8?q=80&w=3687&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    decoding="async"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <h2 className="text-lg mb-3">已训练模型展示区</h2>
        <div className="row g-2">
          {trainTasks.length === 0 && (
            <div className="text-muted text-center py-5">
              <i className="text-40 iconfont icon-hezi_box"></i>
              <div className="mt-2">暂无模型</div>
            </div>
          )}
          {trainTasks.length > 0 &&
            trainTasks.map((task) => (
              <div className="col-4" key={task.id}>
                <div className="item rounded-1">
                  <div className="media">
                    <div className="media-content">
                      <img
                        src={task.featured_image}
                        decoding="async"
                        loading="lazy"
                      />
                    </div>
                    {task.state < 2 && (
                      <div className="media-overlay p-1">
                        <div className="text-xs m-auto ">正在训练</div>
                      </div>
                    )}
                    {task.state === 3 && (
                      <div className="media-overlay p-1">
                        <div className="text-xs m-auto ">训练失败</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default function TrainingModelsPage() {
  const [
    taskIds,
    setTaskIds,
  ] = useAtom(trainTasksAtom)
  const [
    fileList,
    setFileList,
  ] = useState<UploadFile[]>([])

  const { run, loading } = useRequest(
    () =>
      apiInstance.post('/nicetheme/v1/modeling_tasks', {
        img_list: fileList
          .filter((f) => f.status === 'done' && !!f.response)
          .map((f) => f.response!),
      }),
    {
      manual: true,
      onError() {
        message.error('提交任务失败')
      },
      onSuccess({ data }) {
        if (data.code === 1) {
          setTaskIds([
            ...taskIds,
            {
              taskId: data.data.job_id,
              thumbnail: fileList[0].response!,
              state: 0,
            },
          ])
        }
        setFileList([])
      },
    },
  )

  return (
    <main className="site-main ai-main">
      <Aside />
      <div className="ai-content block p-3 p-md-4">
        <div className="ai-training-mode">
          <h2 className="text-lg mb-3">
            上传训练素材（请参考样图图片区，上传 5-20 张不同角度的训练素材）
          </h2>
          <UploadSection
            fileList={fileList}
            onChange={(_fileList) => void setFileList([..._fileList])}
          />
          <div className="mt-auto">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <Button
                  className="ai-download-pay btn btn-primary btn-block"
                  block
                  type="primary"
                  size="large"
                  disabled={
                    fileList.some((f) => f.status !== 'done') ||
                    fileList.length < 5 ||
                    fileList.length > 20 ||
                    loading
                  }
                  loading={loading}
                  onClick={() => run()}
                >
                  开始训练
                  <div className="ai-price-tips">
                    {wpApiSettings.ai_train_model_credit} 积分 / 张
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
