import { atom, useAtom } from 'jotai'
import clsx from 'clsx'
import { portraitAtom, portraitsAtom, stepAtom, targetListAtom } from '../atoms'
import React, { useState } from 'react'
import {
  GetProp,
  UploadFile,
  UploadProps,
  Modal,
  message,
  Upload,
  Spin,
} from 'antd'
import { uploadImage } from '../../../utils'

function UploadSection({
  onFileChange,
  fileList,
  onSuccess,
  ...props
}: {
  onFileChange: (fileList: UploadFile[]) => void
  onSuccess: (url: string) => void
  fileList: UploadFile[]
} & UploadProps) {
  const uploading = fileList.some((file) => file.status === 'uploading')

  return (
    <Upload
      fileList={fileList}
      multiple={false}
      accept="image/*"
      showUploadList={false}
      onChange={({ fileList: newFileList }) => {
        onFileChange(newFileList)
      }}
      beforeUpload={(file) => {
        const isImage = file.type.startsWith('image/')
        if (!isImage) {
          message.error('上传的不是有效图片！')
        }
        return isImage || Upload.LIST_IGNORE
      }}
      customRequest={(options) => {
        uploadImage(
          {
            file: options.file as File,
            token: '',
            post_ID: 0,
          },
          {
            onUploadProgress: (pe) => {
              options.onProgress?.(pe)
            },
          },
        )
          .then(({ data }) => {
            if (!data.uploaded) {
              options.onError?.(new Error('上传失败'))
              return
            }
            options.onSuccess?.(data.url)
            onSuccess(data.url)
          })
          .catch((e) => {
            message.error('上传失败')
            options.onError?.(e)
          })
      }}
      {...props}
    >
      <div className="ai-upload">
        {fileList.length > 0 && (
          <button type="button" className="ai-upload-add btn btn-sm">
            添加图片
          </button>
        )}
        <div
          className="ai-upload-tip text-secondary text-center"
          style={{ width: '100%' }}
        >
          <div className="m-auto">
            {uploading && <Spin />}
            {!uploading && (
              <>
                <i className="text-40 iconfont icon-jia_plus" />
                <div className="text-md">上传/选择模特</div>
                <div className="text-muted text-xs">
                  (每张图片大小限制10M以内)
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Upload>
  )
}

export default function Step2Section() {
  const [
    selection,
    setSelection,
  ] = useAtom(portraitAtom)

  const [
    candidates,
    setCandidates,
  ] = useState<string[]>([
    'https://images.unsplash.com/photo-1674851993235-f730432c37f8?q=80&w=3687&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D1',
  ])

  const [
    step,
  ] = useAtom(stepAtom)
  const [
    fileList,
    setFileList,
  ] = useState<UploadFile[]>([])

  return (
    <div
      className={clsx('ai-content-step2', step !== 2 && 'ai-content-hidden')}
    >
      <UploadSection
        fileList={fileList}
        onSuccess={(url) => {
          setCandidates([
            url,
            ...candidates,
          ])
          setSelection(url)
        }}
        onFileChange={(_fileList) => {
          setFileList([..._fileList])
        }}
      />
      <h2 className="text-lg mt-4">目标人脸列表</h2>
      <div className="ai-image-list mt-3">
        <div className="row g-3">
          {candidates.map((c) => (
            <div className="col-4 col-md-3 col-xxl-2" key={c}>
              <div
                className={clsx('item rounded', {
                  active: selection === c,
                })}
                onClick={() => {
                  setSelection(c)
                }}
              >
                <div className="media media-3x4">
                  <div className="media-content">
                    <img src={c} decoding="async" loading="lazy" />
                  </div>
                </div>
                <div className="item-action">
                  <span>
                    <i className="iconfont icon-xiaoyan_check" />
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
