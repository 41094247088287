import clsx from 'clsx'
import { useEffect, useState } from 'react'
import UploadSection from './components/UploadSection'
import { Button, Spin, UploadFile, message } from 'antd'
import { useRequest } from 'ahooks'
import apiInstance from '../../api/axios'

export default function AIScalePage() {
  const [
    factor,
    setFactor,
  ] = useState(+window.wpApiSettings.scale_options[0].option)

  const [
    fileList,
    setFileList,
  ] = useState<UploadFile[]>([])

  const [
    result,
    setResult,
  ] = useState('')

  const [
    isPolling,
    setIsPolling,
  ] = useState(false)

  const { run: queryTask, cancel: cancelPolling } = useRequest(
    (taskId: string) =>
      apiInstance.get('/nicetheme/v1/upscale_tasks', {
        params: { task_id: taskId },
      }),
    {
      pollingInterval: 5000,
      manual: true,
      onSuccess({ data: { data } }) {
        if (data?.code > 0) {
          cancelPolling()
          setIsPolling(false)
        }
        if (data?.code === 1) {
          setResult(data.data)
          message.success('生成成功')
        }
        if (data?.code > 1) {
          message.error(`生成失败，原因：${data.msg}`)
        }
      },
    },
  )

  const { run, loading } = useRequest(
    () =>
      apiInstance.post('/nicetheme/v1/upscale_image', {
        img: fileList[0].response,
        upscale_factor: factor,
      }),
    {
      manual: true,
      onSuccess({ data }) {
        if (!data.success) return
        setIsPolling(true)
        queryTask(data.data.job_id)
      },
    },
  )

  return (
    <main className="site-main ai-main">
      <div className="ai-aside block p-3 p-md-4">
        <div className="mb-4">
          <h2 className="text-lg mb-3">上传目标图片</h2>
          <UploadSection
            fileList={fileList}
            onFileChange={(_fileList) => void setFileList([..._fileList])}
          />
        </div>
        <div className="mb-4">
          <h2 className="text-lg mb-3">选择提高分辨率的倍数</h2>
          <div className="ai-zoom-numbers row g-2">
            {window.wpApiSettings.scale_options.map((n) => (
              <div className="col-6" key={n.option}>
                <div className="item rounded-1 ">
                  <button
                    onClick={() => setFactor(n.option)}
                    className={clsx(
                      'zoom-button btn btn-outline-light btn-block',
                      { active: factor === n.option },
                    )}
                  >
                    {n.option} 倍
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="ai-actions mt-auto">
          <Button
            disabled={
              loading || isPolling || fileList.some((f) => f.status !== 'done')
            }
            onClick={() => run()}
            loading={loading || isPolling}
            block
            type="primary"
            size="large"
          >
            开始优化
            <div className="ai-price-tips">
              {wpApiSettings.scale_credit} 积分 / 张
            </div>
          </Button>
        </div>
      </div>
      {result ? (
        <div className="ai-content block p-3 p-md-4">
          <h2 className="text-lg mb-3">结果图</h2>
          <div className="ai-zoom-content ">
            <div className="ai-zoom-result border-light rounded mb-3 mb-md-4 mb-xxl-5">
              <img src={result} />
              <div className="item-ai-actions d-flex flex-row flex-wrap">
                <a
                  href="javascript:;"
                  onClick={() => {
                    const aDom = document.createElement('a')
                    aDom.href = result
                    aDom.download = 'result'
                    aDom.click()
                  }}
                  className="btn btn-light btn-xs btn-icon btn-rounded"
                >
                  <span>
                    <i className="iconfont icon-qudibu_to-bottom" />
                  </span>
                </a>
                <a
                  href="javascript:;"
                  onClick={() => void setFileList([])}
                  className="btn btn-light btn-xs btn-icon btn-rounded ms-2"
                >
                  <span>
                    <i className="iconfont icon-guanbi-xiao_close-small" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="ai-content block p-3 p-md-4">
          <h2 className="text-lg mb-3">结果图</h2>
          <div className="ai-zoom-content ">
            <div className="ai-zoom-empty border-light rounded mb-3 mb-md-4 mb-xxl-5">
              {isPolling ? (
                <div className="text-secondary text-center m-auto">
                  <Spin size="large" />
                  <div className="text-md mt-3 text-danger">
                    请保持网络畅通，不可关闭当前窗口，否则会导致写真图失败
                  </div>
                </div>
              ) : (
                <div className="text-secondary text-center m-auto">
                  <i className="text-40 text-muted iconfont icon-hezi_box" />
                  <div className="text-md">暂无结果</div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </main>
  )
}
