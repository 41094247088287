import { imgUrlsAtom, stepAtom } from '../atoms'

import { PlusOutlined } from '@ant-design/icons'
import { Upload, message, UploadFile, UploadProps, Spin } from 'antd'
import { useState } from 'react'
import { uploadImage } from '../../../utils/api'

import { atom, useAtom } from 'jotai'
import clsx from 'clsx'
import apiInstance from '../../../api/axios'
import { useRequest } from 'ahooks'

function UploadSection({
  onFileChange,
  fileList,
  onUploadSuccess,
  children,
  ...props
}: {
  onFileChange: (fileList: UploadFile[]) => void
  onUploadSuccess: (url: string) => void
  fileList: UploadFile[]
  children?: React.ReactNode
} & UploadProps) {
  const uploading = fileList.some((file) => file.status === 'uploading')

  return (
    <Upload
      fileList={fileList}
      multiple={false}
      accept="image/*"
      showUploadList={false}
      onChange={({ fileList: newFileList }) => {
        onFileChange(newFileList)
      }}
      beforeUpload={(file) => {
        const isImage = file.type.startsWith('image/')
        if (!isImage) {
          message.error('上传的不是有效图片！')
        }
        return isImage || Upload.LIST_IGNORE
      }}
      customRequest={(options) => {
        uploadImage(
          {
            file: options.file as File,
            token: '',
            post_ID: 0,
          },
          {
            onUploadProgress: (pe) => {
              options.onProgress?.(pe)
            },
          },
        )
          .then(({ data }) => {
            if (!data.uploaded) {
              options.onError?.(new Error('上传失败'))
              return
            }
            options.onSuccess?.(data.url)
            onUploadSuccess(data.url)
          })
          .catch((e) => {
            message.error('上传失败')
            options.onError?.(e)
          })
      }}
      {...props}
    >
      <div className="ai-upload">
        {fileList.length > 0 && (
          <button type="button" className="ai-upload-add btn btn-sm">
            添加图片
          </button>
        )}
        {fileList.length === 0 && (
          <div
            className="ai-upload-tip text-secondary text-center"
            style={{ width: '100%' }}
          >
            <div className="m-auto">
              {uploading && <Spin />}
              {!uploading && (
                <>
                  <i className="text-40 iconfont icon-jia_plus" />
                  <div className="text-md">上传/选择模特</div>
                  <div className="text-muted text-xs">
                    (每张图片大小限制10M以内)
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {children}
      </div>
    </Upload>
  )
}

export default function Step1Section() {
  const [
    fileList,
    setFileList,
  ] = useState<UploadFile[]>([])
  const [
    step,
    setStep,
  ] = useAtom(stepAtom)
  const [
    selection,
    setSelection,
  ] = useAtom(imgUrlsAtom)

  const [
    selectedCats,
    setSelectedCats,
  ] = useState([
    { term_id: 0, name: '全部' },
    { term_id: 0, name: '全部' },
    { term_id: 0, name: '全部' },
  ])

  const { data: catData } = useRequest(() =>
    apiInstance.get('/nicetheme/v1/model_photo_cats'),
  )

  const typeMap = {
    all: '全部',
    quarter: '专享素材',
    year: '尊享素材',
    free: '免费素材',
    paid: '已购素材',
  }
  const [
    type,
    setType,
  ] = useState('all')
  const [
    candidates,
    setCandidates,
  ] = useState<{ type: string; url: string }[]>([])

  const { loading } = useRequest(
    () =>
      apiInstance.get('/nicetheme/v1/model_photos', {
        params: {
          cats: selectedCats.map((c) => c.term_id).join(','),
        },
      }),
    {
      onSuccess({ data }) {
        setCandidates([...data.data])
      },
      refreshDeps: [selectedCats],
    },
  )

  if (!catData) return <div></div>

  const [
    firstCats,
    secondaryCats,
    thirdCats,
  ] = catData?.data?.data

  return (
    <div
      className={clsx('ai-content-step1', step !== 1 && 'ai-content-hidden')}
    >
      <UploadSection
        fileList={fileList}
        onFileChange={(_fileList) => void setFileList([..._fileList])}
        onUploadSuccess={(url) =>
          setCandidates([
            { type: 'unknown', url },
            ...candidates,
          ])
        }
      >
        <div className="ai-upload-images py-3 py-md-4">
          <div className="row g-3 justify-content-center">
            {selection.map((c) => (
              <div className="col-4 col-md-3 col-xxl-2" key={c}>
                <div className={clsx('item rounded')}>
                  <div className="media media-3x4">
                    <div className="media-content">
                      <img src={c} decoding="async" loading="lazy" />
                    </div>
                  </div>
                  <div
                    className="item-action"
                    onClick={() => {
                      setFileList(fileList.filter((f) => f.response !== c))
                    }}
                  >
                    <span>
                      <i className="iconfont icon-guanbi_close1" />
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </UploadSection>
      <div className="ai-tab-list mt-3 mt-md-4">
        <ul>
          <li>
            <a>{(typeMap as unknown)[type] ?? '全部'}</a>
            <ul>
              <li>
                <a href="javascript:;" onClick={() => setType('all')}>
                  全部
                </a>
              </li>
              <li>
                <a onClick={() => setType('free')}>免费素材</a>
              </li>
              <li>
                <a onClick={() => setType('quarter')}>专享素材</a>
              </li>
              <li>
                <a onClick={() => setType('year')}>尊享素材</a>
              </li>
              <li>
                <a onClick={() => setType('paid')}>已购素材</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript:;">{selectedCats[0].name}</a>
            <ul>
              <li>
                <a
                  href="javascript:;"
                  onClick={() =>
                    setSelectedCats([
                      { term_id: 0, name: '全部' },
                      selectedCats[1],
                      selectedCats[2],
                    ])
                  }
                >
                  全部
                </a>
              </li>
              {firstCats.map((cat: any) => (
                <li key={cat.term_id}>
                  <a
                    href="javascript:;"
                    onClick={() =>
                      setSelectedCats([
                        { term_id: cat.term_id, name: cat.name },
                        selectedCats[1],
                        selectedCats[2],
                      ])
                    }
                  >
                    {cat.name}
                  </a>
                </li>
              ))}
            </ul>
          </li>
          <li>
            <a href="javascript:;">{selectedCats[1].name}</a>
            <ul>
              <li>
                <a
                  href="javascript:;"
                  onClick={() =>
                    setSelectedCats([
                      selectedCats[0],
                      { term_id: 0, name: '全部' },
                      selectedCats[2],
                    ])
                  }
                >
                  全部
                </a>
              </li>
              {secondaryCats.map((cat: any) => (
                <li key={cat.term_id}>
                  <a
                    href="javascript:;"
                    onClick={() =>
                      setSelectedCats([
                        selectedCats[0],
                        { term_id: cat.term_id, name: cat.name },
                        selectedCats[2],
                      ])
                    }
                  >
                    {cat.name}
                  </a>
                </li>
              ))}
            </ul>
          </li>
          <li>
            <a href="javascript:;">{selectedCats[2].name}</a>
            <ul>
              <li>
                <a
                  href="javascript:;"
                  onClick={() =>
                    setSelectedCats([
                      selectedCats[0],
                      selectedCats[1],
                      { term_id: 0, name: '全部' },
                    ])
                  }
                >
                  全部
                </a>
              </li>
              {thirdCats.map((cat: any) => (
                <li key={cat.term_id}>
                  <a
                    href="javascript:;"
                    onClick={() =>
                      setSelectedCats([
                        selectedCats[0],
                        selectedCats[1],
                        { term_id: cat.term_id, name: cat.name },
                      ])
                    }
                  >
                    {cat.name}
                  </a>
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </div>
      <div className="ai-image-list mt-3">
        {loading ? (
          <div className="mx-auto">
            <Spin />
          </div>
        ) : (
          <div className="row g-3">
            {candidates
              .filter((c) => (type === 'all' ? true : c.type === type))
              .map((c) => (
                <div className="col-4 col-md-3 col-xxl-2" key={c.url}>
                  <div
                    className={clsx('item rounded', {
                      active: !!selection?.find((s) => s === c.url),
                    })}
                    onClick={() => {
                      if (selection?.find((s) => s === c.url)) {
                        setSelection(selection.filter((s) => s !== c.url))
                      } else {
                        setSelection([
                          ...(selection || []),
                          c.url,
                        ])
                      }
                    }}
                  >
                    <div className="media media-3x4">
                      <div className="media-content">
                        <img src={c.url} decoding="async" loading="lazy" />
                      </div>
                    </div>
                    <div className="item-action">
                      <span>
                        <i className="iconfont icon-xiaoyan_check" />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  )
}
