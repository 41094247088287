import { Spin } from 'antd'
import { useAtom } from 'jotai'
import clsx from 'clsx'
import { resultAtom as resultsAtom, stepAtom } from '../atoms'

export default function Step3Section() {
  const [
    step,
    setStep,
  ] = useAtom(stepAtom)
  const [
    results,
    setResults,
  ] = useAtom(resultsAtom)

  const removeResult = (number: number) =>
    void setResults(results.filter((r) => r.number !== number))
  return (
    <div
      className={clsx('ai-content-step3', step !== 3 && 'ai-content-hidden')}
    >
      {results.length === 0 && (
        <div className="ai-zoom-empty border-light rounded">
          <div className="text-secondary text-center m-auto">
            <Spin size="large" />
            <div className="text-md mt-3 text-danger">
              请保持网络畅通，不可关闭当前窗口，否则会导致写真图失败
            </div>
          </div>
        </div>
      )}
      {results.length > 0 && (
        <>
          <div className="ai-created-result mb-3 mb-md-4 mb-xxl-5">
            <div className="result-primary text-center border border-light rounded">
              <img src={results[0].img_url} decoding="async" loading="lazy" />
              <div className="result-actions d-flex flex-row flex-wrap">
                <a href="" className="btn btn-light btn-xs btn-icon">
                  <span>
                    <i className="iconfont icon-qudibu_to-bottom" />
                  </span>
                </a>
                <a
                  href="javascript:;"
                  onClick={() => void removeResult(results[0].number)}
                  className="btn btn-light btn-xs btn-icon ms-2"
                >
                  <span>
                    <i className="iconfont icon-guanbi-xiao_close-small" />
                  </span>
                </a>
              </div>
              <div className="result-number">1</div>
            </div>
            {results.length > 1 && (
              <div className="result-list mt-3">
                <ul>
                  {results.slice(1).map((r) => (
                    <li
                      className="item text-center border border-light rounded"
                      key={r.number}
                    >
                      <img src={r.img_url} decoding="async" loading="lazy" />
                      <div className="item-ai-actions d-flex flex-row flex-wrap">
                        <a
                          href=""
                          className="btn btn-light btn-xs btn-icon btn-rounded"
                        >
                          <span>
                            <i className="iconfont icon-qudibu_to-bottom" />
                          </span>
                        </a>
                        <a
                          href="javascript:;"
                          onClick={() => void removeResult(r.number)}
                          className="btn btn-light btn-xs btn-icon btn-rounded ms-2"
                        >
                          <span>
                            <i className="iconfont icon-guanbi-xiao_close-small" />
                          </span>
                        </a>
                      </div>
                      <div className="item-number">{r.number}</div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </>
      )}
      {results.length > 0 && (
        <div className="mt-auto">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <button className="ai-download-pay btn btn-primary btn-block">
                下载高清无水印图片
                <div className="ai-price-tips">
                  {wpApiSettings.hd_download_credit} 积分 / 张
                </div>
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="ai-popup">
        <div className="ai-popup-overlay" />
        <div className="ai-popup-content p-3 p-md-4">
          <h2 className="text-lg mb-3">选择图片(可多选)</h2>
          <div className="ai-choose-images mb-3 mb-md-4">
            <div className="row g-3">
              <div className="col-4 col-md-3 col-xxl-2">
                <div className="item rounded active">
                  <div className="media media-3x4">
                    <div className="media-content">
                      <img
                        src="https://images.unsplash.com/photo-1674851993235-f730432c37f8?q=80&w=3687&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        decoding="async"
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="item-action">
                    <span>
                      <i className="iconfont icon-xiaoyan_check" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-4 col-md-3 col-xxl-2">
                <div className="item rounded">
                  <div className="media media-3x4">
                    <div className="media-content">
                      <img
                        src="https://images.unsplash.com/photo-1674851993235-f730432c37f8?q=80&w=3687&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        decoding="async"
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="item-action">
                    <span>
                      <i className="iconfont icon-xiaoyan_check" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-4 col-md-3 col-xxl-2">
                <div className="item rounded">
                  <div className="media media-3x4">
                    <div className="media-content">
                      <img
                        src="https://images.unsplash.com/photo-1674851993235-f730432c37f8?q=80&w=3687&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        decoding="async"
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="item-action">
                    <span>
                      <i className="iconfont icon-xiaoyan_check" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-4 col-md-3 col-xxl-2">
                <div className="item rounded">
                  <div className="media media-3x4">
                    <div className="media-content">
                      <img
                        src="https://images.unsplash.com/photo-1674851993235-f730432c37f8?q=80&w=3687&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        decoding="async"
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="item-action">
                    <span>
                      <i className="iconfont icon-xiaoyan_check" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-4 col-md-3 col-xxl-2">
                <div className="item rounded">
                  <div className="media media-3x4">
                    <div className="media-content">
                      <img
                        src="https://images.unsplash.com/photo-1674851993235-f730432c37f8?q=80&w=3687&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        decoding="async"
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="item-action">
                    <span>
                      <i className="iconfont icon-xiaoyan_check" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-4 col-md-3 col-xxl-2">
                <div className="item rounded">
                  <div className="media media-3x4">
                    <div className="media-content">
                      <img
                        src="https://images.unsplash.com/photo-1674851993235-f730432c37f8?q=80&w=3687&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        decoding="async"
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="item-action">
                    <span>
                      <i className="iconfont icon-xiaoyan_check" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row gx-3 justify-content-md-end">
            <div className="col-5 col-md-3">
              <button className="btn btn-light btn-block">取消操作</button>
            </div>
            <div className="col-7 col-md-5">
              <button className="btn btn-primary btn-block">
                下载高清无水印图片
                <div className="ai-price-tips">共消耗10积分 </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
