import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'

// 定义基础URL
const baseURL = '/api' // 'https://api.huanxiangdashi.com';

const huaxiangApi = axios.create({
  baseURL,
})

const wpApi = axios.create({})

interface UploadImageRequestParams {
  token: string
  post_ID: string | number
  file: File
}

// Define the successful response structure

interface UploadImageSuccessResponse {
  uploaded: boolean
  url: string
  data: {
    nonce: string
    id: number
  }
}

// Define the error response structure

export interface UploadImageErrorResponse {
  error: {
    number: number
    message: string
  }
}

// Define the function to make the request

export async function uploadImage(
  params: UploadImageRequestParams,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<UploadImageSuccessResponse>> {
  const formData = new FormData()
  formData.append('upload', params.file)

  return wpApi.post('/wp-admin/admin-ajax.php', formData, {
    params: {
      action: 'image-upload',
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...config,
  })
}

// 定义请求和响应的接口

interface SignatureRequest {
  user_id: number
  token: string
}

interface SignatureResponse {
  code: number
  msg: string
  data: string
}

interface FastImageRequest {
  target_list: Array<string>
  portrait: string
}

interface FastImageResponse {
  code: number
  msg: string
  data: string
}

// 签名API

export const fetchSignature = async (
  data: SignatureRequest,
): Promise<SignatureResponse> => {
  const response: AxiosResponse<SignatureResponse> = await huaxiangApi.post(
    '/users/signature/',
    data,
  )
  return response.data
}

// 快速写真任务提交API

export const submitFastImageTask = async (
  data: FastImageRequest,
  signature: string,
): Promise<FastImageResponse> => {
  const response: AxiosResponse<FastImageResponse> = await huaxiangApi.post(
    '/image/fast_img',
    data,
    {
      headers: {
        Authorization: signature,
      },
    },
  )
  return response.data
}

interface FastImageQueryRequest {
  task_id: Array<number>
}

interface FastImageQueryResponseData {
  number: number
  img_name: string
  img_url: string
}

interface FastImageQueryResponse {
  code: number
  msg: string
  data: FastImageQueryResponseData[]
}

// 快速写真任务查询API

export const queryFastImageTask = async (
  data: FastImageQueryRequest,
  signature: string,
): Promise<FastImageQueryResponse> => {
  const response: AxiosResponse<FastImageQueryResponse> = await huaxiangApi.get(
    '/image/fast_img',
    {
      headers: {
        Authorization: signature,
      },
      params: data,
    },
  )
  return response.data
}

interface CheckImageRequest {
  img_list: Array<string>
}

interface CheckImageResponseData {
  code: number
  frontal: string
  message: string
  url: string
}

interface CheckImageResponse {
  code: number
  msg: string
  data: CheckImageResponseData[]
}

// 建模图像校验API

export const checkModelImage = async (
  data: CheckImageRequest,
  signature: string,
): Promise<CheckImageResponse> => {
  const response: AxiosResponse<CheckImageResponse> = await huaxiangApi.post(
    '/image/check_img',
    data,
    {
      headers: {
        Authorization: signature,
      },
    },
  )
  return response.data
}

// 定义AI建模写真任务提交的请求和响应接口

interface AIModelingRequest {
  model_id: string
  img_url: Array<string>
  enhancement: boolean
  face_img?: string // 可选参数
}

interface AIModelingResponse {
  code: number
  msg: string
  data: string
}

// AI建模写真任务提交API

export const submitAIModelingTask = async (
  data: AIModelingRequest,
  signature: string,
): Promise<AIModelingResponse> => {
  const response: AxiosResponse<AIModelingResponse> = await huaxiangApi.post(
    '/image/fast_img',
    data,
    {
      headers: {
        Authorization: signature,
      },
    },
  )
  return response.data
}

interface AdvancedImageQueryRequest {
  task_id: Array<number>
}

interface AdvancedImageQueryResponseDataDetails {
  cost_time: number
  model_id: string
  img_name: string
  img_url: string
}

interface AdvancedImageQueryResponseData {
  number: number
  code: string
  message: string
  response_data: AdvancedImageQueryResponseDataDetails
}

interface AdvancedImageQueryResponse {
  code: number
  msg: string
  data: AdvancedImageQueryResponseData[]
}

// AI建模写真任务查询API

export const queryAdvancedImageTask = async (
  data: AdvancedImageQueryRequest,
  signature: string,
): Promise<AdvancedImageQueryResponse> => {
  const response: AxiosResponse<AdvancedImageQueryResponse> =
    await huaxiangApi.get('/image/advanced_img', {
      headers: {
        Authorization: signature,
      },
      params: data,
    })
  return response.data
}

interface UpscaleImageRequest {
  img: string
  upscale_factor: string
}

interface UpscaleImageResponse {
  code: number
  msg: string
  data: string
}

// 图片放大任务提交API

export const submitUpscaleImageTask = async (
  data: UpscaleImageRequest,
  signature: string,
): Promise<UpscaleImageResponse> => {
  const response: AxiosResponse<UpscaleImageResponse> = await huaxiangApi.post(
    '/image/upscale_img',
    data,
    {
      headers: {
        Authorization: signature,
      },
    },
  )
  return response.data
}

// 定义图片放大任务查询的请求和响应接口

interface UpscaleImageQueryRequest {
  task_id: Array<number>
}

interface UpscaleImageQueryResponse {
  code: number
  msg: string
  data: string[]
}

// 图片放大任务查询API

export const queryUpscaleImageTask = async (
  data: UpscaleImageQueryRequest,
  signature: string,
): Promise<UpscaleImageQueryResponse> => {
  const response: AxiosResponse<UpscaleImageQueryResponse> =
    await huaxiangApi.get('/image/upscale_img', {
      headers: {
        Authorization: signature,
      },
      params: data,
    })
  return response.data
}

interface AITrainingRequest {
  img_list: Array<string>
}

interface AITrainingResponseData {
  job_id: number
  model_id: string
}

interface AITrainingResponse {
  code: number
  msg: string
  data: AITrainingResponseData
}

// AI建模任务提交API

export const submitAITrainingTask = async (
  data: AITrainingRequest,
  signature: string,
): Promise<AITrainingResponse> => {
  const formData = new FormData()
  Object.values(data).forEach((v) => {
    formData.append('img_list', v)
    formData.append('img_list', v)
  })
  const response: AxiosResponse<AITrainingResponse> = await huaxiangApi.post(
    '/image/training_img',
    formData,
    {
      headers: {
        Authorization: signature,
      },
    },
  )
  return response.data
}

// 定义AI建模任务查询的请求和响应接口

interface AITrainingQueryRequest {
  job_id: string
}

interface AITrainingQueryResponseData {
  id: number
  state: number
  message: string
  create_time: string
  Result: string
}

interface AITrainingQueryResponse {
  code: number
  msg: string
  data: AITrainingQueryResponseData
}

// AI建模任务查询API

export const queryAITrainingTask = async (
  data: AITrainingQueryRequest,
  signature: string,
): Promise<AITrainingQueryResponse> => {
  const response: AxiosResponse<AITrainingQueryResponse> =
    await huaxiangApi.get('/image/training_img', {
      headers: {
        Authorization: signature,
      },
      params: data,
    })
  return response.data
}
