import { Button, Switch, UploadFile } from 'antd'
import { atom, useAtom } from 'jotai'
import clsx from 'clsx'
import { enhancementAtom, imgUrlsAtom, modelIdAtom, stepAtom } from '../atoms'
import UploadSection from '../../../components/UploadSection'
import { useRequest } from 'ahooks'
import apiInstance from '../../../api/axios'
import { useNavigate } from 'react-router-dom'

export default function Step2Section() {
  const [
    isEnhancement,
    setIsEnhancement,
  ] = useAtom(enhancementAtom)
  const [
    modelId,
    setModelId,
  ] = useAtom(modelIdAtom)
  const [
    step,
    setStep,
  ] = useAtom(stepAtom)

  const { data } = useRequest(
    () => apiInstance.get('/nicetheme/v1/modeling_tasks'),
    {},
  )

  const navigate = useNavigate()

  const trainTasks = data?.data?.data || []

  return (
    <div
      className={clsx('ai-content-step2', step !== 2 && 'ai-content-hidden')}
    >
      <div className="d-flex justify-content-between align-items-center mt-4">
        <h2 className="text-lg">模型列表</h2>
        <span className="d-flex align-items-center">
          <span className="pe-2">画质增强</span>{' '}
          <Switch
            size="small"
            value={isEnhancement}
            onChange={(e) => void setIsEnhancement(e)}
          />
        </span>
      </div>
      {!trainTasks.length && (
        <div className="text-secondary text-center m-auto mt-3">
          <i className="text-40 text-muted iconfont icon-hezi_box"></i>
          <div className="text-md">暂无模型数据</div>
          <div className="mt-3">
            <a
              href="javascript:;"
              onClick={() => void navigate('/train-model')}
              className="btn btn-dark"
            >
              前往AI建模
            </a>
          </div>
        </div>
      )}
      {trainTasks.length > 0 && (
        <div className="ai-image-list mt-3">
          <div className="row g-3">
            {trainTasks
              ?.filter((t) => t.state === 2)
              .map((task) => (
                <div
                  className="col-4 col-md-3 col-xxl-2"
                  key={task.id}
                  onClick={() => setModelId(task.model_id)}
                >
                  <div
                    className={clsx('item rounded', {
                      active: modelId === task.model_id,
                    })}
                  >
                    <div className="media media-3x4">
                      <div className="media-content">
                        <img
                          src={task.featured_image}
                          decoding="async"
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div className="item-action">
                      <span>
                        <i className="iconfont icon-xiaoyan_check" />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            <div className="col-4 col-md-3 col-xxl-2">
              <div className="item rounded active">
                <a
                  href="javascript:;"
                  onClick={() => void navigate('/train-model')}
                  className="media media-3x4"
                >
                  <div className="media-content"></div>
                  <div className="media-overlay">
                    <div className="m-auto text-center">
                      <i className="text-xxl iconfont icon-jia_plus"></i>
                      <div className="text-md">新增模型</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
